import Vue from 'vue'
import VueRouter from 'vue-router'
import Front from "./front/index"
import Backend from "./backend/index";
//import Func from "@/common/func.js";
import RQ from '@/api/request.js';
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let routes = [
]
routes = routes.concat(Front, Backend);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
function findMenu(list, name) {
  //Func.log(list,name);
  for (var i in list) {
    var data = list[i];
    //Func.log("xxx", data, name);
    if (data.children && data.children.length>0) {
      for (var j in data.children) {
        var children = data.children[j];
        if (children.router == name) {
          return true;
        }
      }
    }else if (data.router == name) {
      return true;
    }
  }
  return false;
}
router.beforeEach((to, from, next) => {
  if (to.name == "Login" || to.name == "Test" || to.name == "DownloadApk") {
    next();
  } else {
    RQ.postAction("/sysadminsystem/getmenus", {}).then((rs) => {
      if (rs.code == 1) {
        if (findMenu(rs.list, to.name)) {
          next();
        }
      }
    });
  }
});
export default router

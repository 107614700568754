<template>
  <div id="app" v-if="vshow">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      vshow: false,
    };
  },
  created() {
    document.title ="得力助手管理后台";
    this.vshow = true;
  },
};
</script>
<style src="./assets/com.css">
body {
  padding: 0;
  margin: 0;
}
</style>

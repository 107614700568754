<template>
    <div>
        <span v-if="!where.loading">
            <span class="f-primary" v-if="areaInfo[tag]">{{ areaInfo[tag] }}</span>
            <span class="f-disable" v-else>{{ tag }}</span>
        </span>
    </div>
</template>
<script>
export default {
    props: {
        tag: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            url: {
                getarealist: "/sysarea/getarealist",
            },
            where: {
                name: "",
                tag: "",
                sort_key: "",
                sort_value: "",
                page: 1,
                pageSize: 20000,
                total: 0,
                loading: true,
            },
            areaInfo: {},
        }
    },
    created() {
        this.loadList();
    },
    methods: {
        loadList: function () {
            var areaInfo = this.$func.get("area-info");
            var now = this.$func.now();
            if (!areaInfo || (areaInfo && areaInfo.overTime < now)) {
                this.where.loading = true;
                this.$rq.postAction(this.url.getarealist, this.where).then((rs) => {
                    this.where.loading = false;
                    if (rs.code == 1) {
                        areaInfo = {
                            overTime: now + 30 * 60
                        };
                        for (var i in rs.list) {
                            areaInfo[rs.list[i].tag] = rs.list[i].name;
                        }
                        this.$func.save("area-info", areaInfo);
                        this.areaInfo = areaInfo;
                    }
                });
            } else {
                this.areaInfo = areaInfo;
                this.where.loading = false;
            }
        },
    }

}
</script>
<template>
    <div class="img" :style="divStyle">
        <img ref="canvas" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            divStyle: {
                width: this.width + 'px',
                height: this.height + 'px',
            }
        }
    },
    watch:{
        src:{
            handler(newsrc){
                //this.$func.log("创建二维码", newsrc);
                this.findCanvas(newsrc);
            },
            immediate:true,
        }
    },
    props: {
        src: {
            type: String,
            default: () => {
                return ""
            }
        },
        width: {
            type: Number,
            default: () => {
                return 400;
            }
        },
        height: {
            type: Number,
            default: () => {
                return 400;
            }
        }
    },
    methods: {
        findCanvas: function (newsrc) {
            if (this.$refs.canvas) {
                var QRCode = require('qrcode');
                QRCode.toDataURL(newsrc, (error, url) => {
                    if (error) {
                        console.error("=======================qrcode", error);
                    } else {
                        this.$refs.canvas.src = url;
                        //console.log("=======================qrcode2", this.src);
                    }
                })
            }else{
                setTimeout(()=>{
                    this.findCanvas(newsrc);
                },500);
            }
        },
    },
}
</script>
<style scoped>
.img {
    display: inline-block;
}

.img>img {
    width: 100%;
    height: 100%;
}
</style>
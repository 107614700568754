import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import RQ from '@/api/request.js';
import Func from "@/common/func.js";
import VueClipboard from 'vue-clipboard2';
import YCategory from "@/components/backend/category/category.js";
import YQrcode from "@/components/backend/qrcode/qrcode.js";
import YArea from "@/components/backend/area/area.js";
import YAreaTag from "@/components/backend/area/areatag.js";
import Clipboard from 'v-clipboard'
import Main from "@/components/backend/common/main.vue";

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.use(YCategory);
Vue.use(YQrcode);
Vue.use(YArea);
Vue.use(YAreaTag);
Vue.use(Clipboard)
Vue.component('Main', Main);

Vue.config.productionTip = false
Vue.prototype.$rq = RQ
Vue.prototype.$func = Func
window.$vue=new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

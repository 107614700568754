<template>
  <div class="main">
    <Top></Top>
    <div class="content flex">
      <Left></Left>
      <div class="main-right flex1">
        <slot></slot>
        <div class="fix-bottom" v-if="show">
          <div class="rl">
            <div class="f14 bold f-warning">您有新的消息</div>
            <div class="abl rr po" @click="() => { this.show = !this.show }"><span class="el-icon-close"></span></div>
          </div>
          <div class="mgt10 f12 rcn">
            <div class="f-error">有改价单任务,请尽快完成改价</div>
            <div>今日改价任务数量:<span class="f-primary po" @click="go(1)">{{ task.today_all_num }}</span>个</div>
            <div>今日改价完成数量:<span class="f-primary po" @click="go(2)">{{ task.today_success_num }}</span>个</div>
            <div>今日改价未完成数:<span class="f-primary po" @click="go(3)">{{ task.today_no_finish_num }}</span>个</div>
            <div>昨日改价任务数量:<span class="f-primary po" @click="go(4)">{{ task.yestoday_all_num }}</span>个</div>
            <div>昨日改价完成数量:<span class="f-primary po" @click="go(5)">{{ task.yestoday_success_num }}</span>个</div>
            <div>昨日改价未完成数:<span class="f-primary po" @click="go(6)">{{ task.yestoday_no_finish_num }}</span>个</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Left from "./left.vue";
import Top from "./top.vue";
export default {
  components: {
    Left,
    Top,
  },
  data() {
    return {
      url: {
        geteditpricetaskcount: "/systask/geteditpricetaskcount"
      },
      show: false,
      task: {
        "today_all_num": 0,
        "today_success_num": 0,
        "today_no_finish_num": 0,
        "yestoday_all_num": 0,
        "yestoday_success_num": 0,
        "yestoday_no_finish_num": 0,
      }
    };
  },
  created() {
    this.loadNocite();
  },
  methods: {
    go: function (v) {
      let user = this.$func.get("user-info");
      var name = "BackendOperateSearchAnalyze";
      var tab = "t3";
      if (user.role_name == "admin" || user.role_name == "subadmin") {
        name = "BackendDataReportV2";
        tab = "t2";
      }
      var arr = this.$func.getFullDay(this.$func.now());
      var arr2 = this.$func.getFullDay(this.$func.now() - 24 * 3600);
      var href;
      if (v == 1) {
        href = this.$router.resolve({
          name: name,
          query: {
            tab: tab,
            task_type: 3,
            task_status: -1,
            send_time_start: arr[0],
            send_time_end: arr[1],
            t: this.$func.now(),
          }
        })
      } else if (v == 2) {
        href = this.$router.resolve({
          name: name,
          query: {
            tab: tab,
            task_type: 3,
            task_status: 2,
            send_time_start: arr[0],
            send_time_end: arr[1],
            t: this.$func.now(),
          }
        })
      } else if (v == 3) {
        href = this.$router.resolve({
          name: name,
          query: {
            tab: tab,
            task_type: 3,
            task_status: 0,
            send_time_start: arr[0],
            send_time_end: arr[1],
            t: this.$func.now(),
          }
        })
      } else if (v == 4) {
        href = this.$router.resolve({
          name: name,
          query: {
            tab: tab,
            task_type: 3,
            task_status: -1,
            send_time_start: arr2[0],
            send_time_end: arr2[1],
            t: this.$func.now(),
          }
        })
      } else if (v == 5) {
        href = this.$router.resolve({
          name: name,
          query: {
            tab: tab,
            task_type: 3,
            task_status: 2,
            send_time_start: arr2[0],
            send_time_end: arr2[1],
            t: this.$func.now(),
          }
        })
      } else if (v == 6) {
        href = this.$router.resolve({
          name: name,
          query: {
            tab: tab,
            task_type: 3,
            task_status: 0,
            send_time_start: arr2[0],
            send_time_end: arr2[1],
            t: this.$func.now(),
          }
        })
      }
      window.open(href.href);
      //window.location.reload();
    },
    geteditpricetaskcount: function () {
      return this.$rq.postAction(this.url.geteditpricetaskcount, {});
    },
    loadNocite: function () {
      this.geteditpricetaskcount().then(rs => {
        if (rs.code == 1) {
          //到时间了
          if (rs.info.today_all_num != 0 || rs.info.today_success_num != 0 || rs.info.today_no_finish_num != 0 || rs.info.yestoday_all_num != 0 || rs.info.yestoday_success_num != 0 || rs.info.yestoday_no_finish_num != 0) {
            this.task = rs.info;
            this.show = true;
          }

        }
      });
    },
  }
};
</script>
<style scoped>
.main {
  height: 100vh;
}

.main-right {
  min-height: calc(100vh - 60px);
  background-color: #f2f1f1;
  overflow: hidden;
  overflow-y: scroll;
  padding: 20px;
  box-sizing: border-box;
}

.fix-bottom {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid #ebeef5;
  transition: opacity .3s, transform .3s, left .3s, right .3s, top .4s, bottom .3s;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
  padding: 8px 12px;
  z-index: 1000;
}

.rr {
  right: 0px;
  top: 0px;
}

.rcn>div {
  margin-bottom: 10px;
}
</style>
<template>
  <div class="flex ac b-border">
    <div v-for="n in level" :key="n" class="li-list mgb20">
      <div>
        <el-input v-model="vals[n]" @input="(v) => { changeV(v, getChildren(n)) }" suffix-icon="el-icon-search"
          placeholder="名称/拼音首字母" style="width: auto">
        </el-input>
      </div>
      <div v-for="(item, index) in getChildren(n)" :key="index" class="li-hang po"
        :class="{ active: isActive(n, index, item) }" @click="menuClick(n, index, item)" v-show="item.show">
        <div class="w-w flex ac">
          <div class="flex1">
            <div class="flex ac">
              <div class="flex1">
                <span style="padding-right:6px;" class="f12 f-warning el-icon-lock" v-if="item.islock == 1"></span>
                <span style="padding-right:6px;" class="f12 f-warning el-icon-unlock" v-else></span>
                {{ item.label }}
              </div>
              <div>
                <span style="padding-left:10px;" class="f-error f18 el-icon-lock"
                  @click.stop="clickLock(1, item)"></span>
                <span @click.stop="clickLock(2, item)" style="padding-left:10px;"
                  class="f18 f-primary el-icon-unlock"></span>
              </div>
            </div>
            <div class="mgt10">
              <span class="f12 pdr10 f-primary" v-if="item.taobao != undefined">店铺数:{{ item.taobao }}</span>
              <span class="f12 f-success pdr10" v-if="item.taobaosalenum != undefined">下单数:{{
                item.taobaosalenum
              }}</span>
              <el-tooltip class="item" effect="dark" content="分别表示:品牌总数/已抓取品牌数/未抓取品牌数" placement="bottom">
                <span class="f12 f-info" v-if="item.brand_nums != undefined">
                  品牌:{{ item.brand_nums }}/{{ item.get_brand_nums }}/{{ item.un_get_brand_nums }}
                </span>
              </el-tooltip>
            </div>
          </div>
          <div v-if="item.children.length > 0" style="margin-left:6px;">
            <span v-if="item.loadding == false" class="el-icon-arrow-right"></span>
            <span class="demo-spin-icon-load el-icon-refresh" color="#2d8cf0" v-else></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      level: 1,
      levelChose: {},
      vals: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
    };
  },
  props: ["rows", "sync", "value"],
  computed: {
    getChildren: function () {
      return this.getChildrenMethod;
    },
  },
  created() {
    this.initPage();
  },
  methods: {
    clickLock: function (lock, item) {
      //this.$func.log("lock", lock);
      item.islock = lock;
      this.$emit("mylock", lock, item);
    },
    changeV: function (search, datas) {
      //this.$func.log("search", search);
      for (var i in datas) {
        var d = datas[i];
        if (search != "") {
          if (d.label.indexOf(search) != -1 || d.spell.indexOf(search) != -1) {
            d.show = true;
          } else {
            d.show = false;
          }
        } else {
          d.show = true;
        }
      }
      //this.$func.log("xxxxxxxxxxxxx", event.target.value,n);
    },
    isActive: function (level, menuIndex) {
      //this.$func.log(item);
      if (
        this.levelChose[level] &&
        this.levelChose[level].menuIndex == menuIndex
      ) {
        return true;
      }
      return false;
    },
    initPage: function () {
      this.levelChose = {};
      for (var i in this.value) {
        var data = this.value[i];
        var n = parseInt(i) + 1;
        var ndata = this.getChildrenMethod(n);
        var selectData = this.getSelectData(ndata, data.value);
        if (selectData) {
          this.$set(this.levelChose, n, {
            level: n,
            menuIndex: selectData.index,
            value: selectData.select,
          });
          this.level = n;
        }
      }
    },
    getSelectData: function (list, value) {
      for (var i in list) {
        if (list[i].value == value) {
          return { select: list[i], index: i };
        }
      }
    },
    getChildrenMethod: function (n, menuIndex) {
      var data = [];
      var level = n - 1;
      for (var i = 0; i <= this.level; i++) {
        if (i == 0) {
          data = this.rows;
        } else {
          if (menuIndex != undefined) {
            data = data[menuIndex].children;
          } else {
            if (data[this.levelChose[i].menuIndex]) {
              data = data[this.levelChose[i].menuIndex].children;
            }
          }
        }
        if (i == level) {
          break;
        }
      }
      return data;
    },
    menuClick: function (level, menuIndex, item) {
      this.level = level;
      if (this.sync) {
        var n = level;
        this.$set(this.levelChose, n, {
          level: n,
          menuIndex: menuIndex,
          value: item,
        });
        this.emitInput(level);
        this.$emit("load-data", item, () => {
          if (item.children.length > 0) {
            this.level = level + 1;
          }
          this.$emit("on-change", item);
        });
      } else {
        var n2 = level;
        this.$set(this.levelChose, n2, {
          level: n2,
          menuIndex: menuIndex,
          value: item,
        });
        this.emitInput(level);
        //var data = this.getChildren(level);
        if (item.children.length > 0) {
          this.level = level + 1;
        }
        this.$emit("on-change", item);
      }
    },
    emitInput: function (level) {
      var select = [];
      for (var i = 1; i <= level; i++) {
        var data = this.levelChose[i];
        var idata = {
          value: data.value.value,
          label: data.value.label,
          level: data.level,
          index: data.menuIndex,
        };
        idata = Object.assign(idata, data.value);
        select.push(idata);
      }
      this.$emit("input", select);
    },
  },
};
</script>
<style scoped>
.li-list {
  height: 320px;
  overflow: hidden;
  overflow-y: scroll;
}

.b-border {
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 10px;
}

.w-w {
  min-width: 100px;
}

.li-hang {
  padding: 10px 20px;
}

.li-hang.active {
  background-color: #f5f5f5;
}

.li-hang:hover {
  background-color: #f5f5f5;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pdr10 {
  padding-right: 10px;
}
</style>
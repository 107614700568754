import axios from 'axios'
// 创建axios实例
const service = axios.create({
    baseURL:(process.env.VUE_APP_BASE_API.indexOf("http")!=-1?process.env.VUE_APP_BASE_API: document.location.protocol + process.env.VUE_APP_BASE_API), // api的base_url
    timeout: 600000,                 // 请求超时时间
    withCredentials: true,           //携带cookie
})
export default {
    postAction: function (url, parameter) {
        return service({
            url: url,
            method: 'post',
            data: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        })
    },
    httpAction: function (url, parameter, method) {
        return service({
            url: url,
            method: method,
            data: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        });
    },
    putAction: function (url, parameter) {
        return service({
            url: url,
            method: 'put',
            data: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        })
    },
    getAction: function (url, parameter) {
        return service({
            url: url,
            method: 'get',
            params: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        })
    },
    fetchPostStream: function (url, params) {
        return this.fetchStream(url, {
            method: "POST",
            headers: {
                'accept': 'text/event-stream',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });
    },
    fetchGetStream: function (url, params) {
        return this.fetchStream(url, {
            method: "GET",
            headers: {
                'accept': 'text/event-stream',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });
    },
    fetchStream:function(url,params){
        const {onmessage,onclose,...otherParams}=params;
        const push=async (controller,reader)=>{
            const {value,done}=await reader.read();
            if(done){
                controller.close();
                onclose?.();
            }else{
                onmessage?.(this.Utf8ArrayToStr(value));
                controller.enqueue(value);
                push(controller,reader);
            }
        };
        var b=(process.env.VUE_APP_BASE_API.indexOf("http")!=-1?process.env.VUE_APP_BASE_API:document.location.protocol + process.env.VUE_APP_BASE_API);
        return fetch(b+url,otherParams).then(rs=>{
            const reader=rs.body.getReader();
            const stream=new ReadableStream({
                start(controller){
                    push(controller,reader);
                },
            });
            return stream;
        }).then((stream) => new Response(stream, { headers: { 'Content-Type': 'text/html' }}).text());
    },
    Utf8ArrayToStr:function(array){
        var out, i, len, c;
        var char2, char3;

        out = "";
        len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                    // 0xxxxxxx
                    out += String.fromCharCode(c);
                    break;
                case 12: case 13:
                    // 110x xxxx   10xx xxxx
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx  10xx xxxx  10xx xxxx
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }
        return out;
    },
}
